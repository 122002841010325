import { useCallback, useState } from 'react';
import getAccount from '../../api/getAccount';
import { ReviewsJetPlatform } from '../../features/product/types';
import { axiosPrivate } from '../../services/axiosPrivate';
import { useAppSelector } from '../../store';
import { Loading } from '../types';
import removeTrailingSlash from '../utils/removeTrailingSlash';
import { useNotification } from './useNotification';

/* eslint no-console: "warn" */

const useUpgradeLink = () => {
  const { account } = useAppSelector((state) => state.userData);
  const { showErrorToast } = useNotification();
  const { selectedSite } = account ?? {};
  const { instanceId, platform } = selectedSite ?? {};
  const { upgradePlanId, billingCycle } = useAppSelector((state) => state.app);
  const [generatingWixUpgradeLink, setGeneratingWixUpgradeLink] = useState(
    Loading.INITIAL
  );

  const handleGenerateWixUpgradeLink = async () => {
    try {
      setGeneratingWixUpgradeLink(Loading.PENDING);
      const {
        data: { checkoutUrl },
      } = await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/wix/upgrade-link`,
        { instanceId, productId: upgradePlanId, billingCycle }
      );

      setGeneratingWixUpgradeLink(Loading.SUCCESS);
      window.open(checkoutUrl, '_blank');
    } catch (error: any) {
      setGeneratingWixUpgradeLink(Loading.ERROR);

      let errorMessage = '';

      if (error.response) {
        errorMessage = error.response.data.error;
      } else {
        errorMessage = error.message;
      }

      showErrorToast(errorMessage);
    }
  };

  const handleUpgrade = useCallback(async () => {
    if (!platform) {
      return;
    }

    if (platform === ReviewsJetPlatform.WIX) {
      await handleGenerateWixUpgradeLink();
    } else {
      try {
        const {
          data: { accessToken },
        } = await getAccount();

        (window as any).open(
          `${removeTrailingSlash(
            process.env.REACT_APP_REVIEWSJET_WEBSITE!
          )}/pricing?token=${accessToken}`,
          '_blank'
        );
      } catch (error) {
        showErrorToast('An error occurred. Please try it again.');
      }
    }
  }, [platform]);

  return {
    handleUpgrade,
    generatingWixUpgradeLink,
    handleGenerateWixUpgradeLink,
  };
};

export default useUpgradeLink;
